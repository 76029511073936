import clsx from 'clsx';
import { Wrapper } from 'components/Wrapper/Wrapper';

import { TopPageAdditionalImageCardProps } from 'lib/themes/themes';

import styles from './TopPageAdditionalImageCard.module.css';

const TopPageAdditionalImageCard = ({ additionalImage }: TopPageAdditionalImageCardProps) => {
  return (
    <Wrapper
      wrapper={(children) =>
        additionalImage?.link_url ? (
          <a className={styles['c-card']} href={additionalImage?.link_url}>
            {children}
          </a>
        ) : (
          <div className={styles['c-card']}>{children}</div>
        )
      }
    >
      <div className={styles['c-card__pic']}>
        <img src={additionalImage?.image_url} alt={additionalImage.title} />
      </div>
      <div className={styles['c-card__info']}>
        <h3 className={clsx(styles['c-card__info__ttl'], styles['maincolor-txt'])}>
          {additionalImage?.title}
        </h3>
        <p className={styles['c-card__info__desc']}>{additionalImage?.description}</p>
      </div>
    </Wrapper>
  );
};

export default TopPageAdditionalImageCard;
